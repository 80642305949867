import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginService } from './login.service';
import moment from 'moment';
import { SERVER_URL } from '../../environments/environment';
import testGuidelines from '../test-guidelines-data.json';
import { ClinicalNotice } from '../types';

export type QuizListItem = { linkID: string; title: string };

@Injectable({
  providedIn: 'root',
})
export class CnsService {
  constructor(
    private storage: Storage,
    private router: Router,
    private http: HttpClient,
    public loginService: LoginService
  ) {
    this.servername = SERVER_URL;
  }
  public loaded = false;
  public userList = [];
  public userFilteredList = [];
  public CNAcks = [];
  public filteredCNAcks = [];
  public filteredActiveCNAcks = [];
  public CNList = [];
  public CNPublishedList = [];
  public CNFilteredPublishedList = [];
  public CNFilteredActivePublishedList = [];
  public CNDraftList = [];
  public gList: any[];
  public regions: any = [];
  public pgdPermissions: any = [];
  public qList: QuizListItem[];
  public StaffGroups = [];
  public servername;
  public CN: ClinicalNotice = {
    ID: null,
    CNID: null,
    recordID: null,
    firstPublished: null,
    lastPublished: null,
    updated: null,
    archived: null,
    // lastModified: null,
    status: null,
    title: null,
    keywords: null,
    guidelines: null,
    regions: [],
    pgdPermissions: [],
    incPLUS: null,
    incCFR: null,
    sendNotifToCFR: null,
    incCRM: null,
    html: null,
    pdfUrl: null,
  };
  public testUsers = false;
  public inactiveUsers = false;
  public testUsersReload: boolean;

  // should only include CFR CNS.
  public CFR_only = false;

  private static getIDs(CNs) {
    return CNs.map((cn) => cn.CNID);
  }

  public unload() {
    this.userFilteredList = [];
    this.filteredCNAcks = [];
    this.CNList = [];
    this.CNDraftList = [];

    this.testUsers = false;
    this.loaded = false;
  }

  public async setup(isAuthoring: Boolean = false) {
    return new Promise(async (resolve) => {
      try {
        if (!this.loaded || this.CNAcks.length === 0) {
          await this.loadUsers();
          if (!isAuthoring) await this.loadCNAcks();
          await this.loadLiveCNs();

          const cnids = CnsService.getIDs(this.CNList);
          this.CNAcks = this.filterByCN(this.CNAcks, cnids, this.CNList);
        }

        if (!this.loaded || this.testUsersReload) {

          this.userFilteredList = this.userList;
          // console.log('this.userList: ', this.userList);
          // console.log('this.testUsers: ', this.testUsers);
          // console.log('this.inactiveUsers: ', this.inactiveUsers);

          if (!this.testUsers) {
            this.userFilteredList = this.userFilteredList.filter(
              (user) => !user.TestUser
            );
          }
          if (!this.inactiveUsers) {
            this.userFilteredList = this.userFilteredList.filter(
              (user) => !user.InActive
            );
          }

          this.filteredCNAcks = this.CNAcks;
          this.filteredActiveCNAcks = this.filterByActiveCN(
            this.filteredCNAcks
          );
          if (this.CFR_only) {
            this.CNFilteredPublishedList = this.CNPublishedList.filter(
              (CN) => CN.incCFR === true
            );
          } else {
            this.CNFilteredPublishedList = this.CNPublishedList.filter(
              (CN) => CN.incPLUS === true
            );
          }
          this.CNFilteredActivePublishedList = this.CNFilteredPublishedList.filter(
            (CN) => CN.status === 'Active'
          );

          this.addAcks();

          this.gList = await this.getGuidelines();
          this.StaffGroups = [];

          this.qList = await this.getQuizList();

          this.regions = this.getRegions();
          this.pgdPermissions = this.getPGDPermissions();

          this.loadStaffGroups();

          this.loaded = true;
          this.testUsersReload = false;
        }

        resolve(true);
      } catch (error) {
        console.log('cns.service.ts 130' + error.message);
        resolve(false);
      }
    });
  }

  public showDate(CN, date: string) {
    if (!CN[date] || CN[date] === '') {
      return '--';
    }

    return moment(CN[date]).format('MMM D, YYYY');
  }
  public showDate2(CN, date: string) {
    if (!CN[date] || CN[date] === '') {
      return '--';
    }

    return moment(CN[date]).format('Do MMMM, YYYY');
  }

  public showTime(CN, date: string) {
    if (!CN[date] || CN[date] === '') {
      return '--';
    }

    return moment(CN[date]).format('LT');
  }

  async saveCN(CN) {
    let now = moment();
    if (CN.recordID) {
      let body = new HttpParams()
        .set('token', this.loginService.token)
        .set('trust', this.loginService.trust)
        .set('contactID', this.loginService.contactID + "")
        .set('recordID', CN.recordID)
        .set('title', CN.title)
        .set('incCFR', CN.incCFR)
        .set('incPLUS', CN.incPLUS)
        .set('incCRM', CN.incCRM)
        .set('status', CN.status)
        .set('html', CN.html)        
        // .set('lastModified', CN.lastModified)
        .set('keywords', CN.keywords)
        .set('guidelines', JSON.stringify(CN.guidelines))
        .set('pdfURl', CN.pdfUrl ? CN.pdfUrl : '')
        .set('localClinicalUpdate', CN.localClinicalUpdate);

      if (CN.firstPublished) body = body.append('released', CN.firstPublished);
      if (CN.pgdPermissions != null) {
        body = body.append('pgdPermissions', CN.pgdPermissions);
      }
      if (CN.regions != null) {
        body = body.append('regions', CN.regions);
      }
      if (CN.archived) {
        body = body.append('archived', CN.archived); 
      } else {
        body = body.append('updated', now.toISOString()); 
      }

      try {
        var xmlStr = '<person id="1234"><name>John Doe</name></person>';
        const parser = new DOMParser();
        const xmlObj = parser.parseFromString(
          `<content_container>${CN.html}</content_container>`,
          'text/xml'
        );
        const errorNode = xmlObj.querySelector('parsererror');
        if (errorNode) {
          console.log('to errorNode -> %s', errorNode);

          return { status: 500 }; // dont'send
        }
      } catch (e) {
        console.log('' + e);
        return; // dont'send
      }

      let url = `${this.servername}aimerapi/class/CN/live/Update`;

      let res = await this.http
        .post(url, body.toString(), {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        })
        .toPromise()
        .catch((err) => {
          this.logError(err.message);

          if (err.status === 401) {
            this.loginService.logout();
          }
          return err;
        });
      return res;
    }
  }

  public async loadUsers() {
    let body = new HttpParams()
      .set('email', this.loginService.userName)
      .set('token', this.loginService.token)
      .set('trust', this.loginService.trust)
      .set('regions', this.loginService.regions.join(';'))
      .set('contactID', this.loginService.contactID + "")
      .set('mustHaveSameSubscription', this.loginService.matchUsersWithSubscription ? 'true' : 'false')
      .set('product', this.CFR_only ? 'CFR' : '');

    await this.http
      .post(this.servername + 'aimerapi/class/PGD/AllUsers', body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .toPromise()
      .then(
        (data: { success; params }) => {
          if (data.success) {
            this.userList = data.params;
          }
        },
        (err) => {
          this.logError(err);
          this.userFilteredList = [];
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }
  public getAUser(contactID) {
    return this.userList.find((user) => user.ContactID === contactID);
  }

  // going to add all CN and status Active/Archived
  public async getActiveCNs(contactID, active) {
    let userCNs = await this.getCNSForUser(contactID);
    const cnids = CnsService.getIDs(this.CNList);
    let usageLog = await this.getUsageLogForUser(contactID);

    for (let aCN of this.CNFilteredPublishedList) {
      let newUserCN = {
        cnid: parseInt(aCN.CNID),
        timestamp: 0,
        title: aCN.title,
        wyvernID: parseInt(contactID),
        status: aCN.status,
      };
      let CNID = parseInt(aCN.CNID);
      let inList = userCNs.find((x) => x.cnid === CNID);
      if (inList) {
        inList.status = aCN.status;
      } else {
        userCNs.push(newUserCN);
      }
    }

    if (active) {
      userCNs = userCNs.filter((x) => x.status === 'Active');
    }

    // add view time to userCNs
    for (let aCN of userCNs) {
      aCN.viewTime = 0;
      for (let usageLogIndex = usageLog.length -1; usageLogIndex >= 0; usageLogIndex--) {
        // loop through backwards as last entry is the latest
        let usageLogEntry = usageLog[usageLogIndex];
        if (usageLogEntry.ID === 'CNID:-' + aCN.cnid) {
          aCN.viewTime = usageLogEntry.date;
          break;
        }
      }
    }

    // this bit remove CNID that are not Active or Archived!!!
    return this.filterByCN(userCNs, cnids, this.CNList);
  }

  private filterByCN(CNAcks, CNIDs, CNList) {
    let problemCNAcks = CNAcks.filter(({ cnid }) => cnid > 888800);

    for (let aCNAck of problemCNAcks) {
      //find it by title in CNList and update the cnid
      let theCN = CNList.find((cn) => cn.title === aCNAck.title);
      if (theCN) {
        aCNAck.cnid = Number(theCN.CNID);
      }
    }

    return CNAcks.filter(({ cnid }) => CNIDs.includes(`${cnid}`));
  }

  private filterByUsers(CNAcks) {
    const userIDs = this.userFilteredList.map(({ ContactID }) =>
      Number(ContactID)
    );

    return CNAcks.filter((ack) => userIDs.includes(ack.wyvernID));
  }

  private filterByActiveCN(CNAcks) {
    const activeCNs = this.CNList.filter(
      (CN) => CN.status === 'Active'
    ).map((CN) => Number(CN.CNID));

    return CNAcks.filter((ack) => activeCNs.includes(ack.cnid));
  }

  public getAckCountByUser() {
    const output = {};

    for (const { wyvernID } of this.filteredActiveCNAcks) {
      if (wyvernID in output) {
        output[wyvernID] += 1;
      } else {
        output[wyvernID] = 1;
      }
    }

    return output;
  }

  private iswyvernIDInUserList(wyvernID) {
    const user = this.userFilteredList.find(
      (usr) => Number(usr.ContactID) === wyvernID
    );

    return !!user;
  }

  private getAckCountByCNID() {
    const output = {};

    let userList = this.userFilteredList.map(user => Number(user.ContactID));

    for (const ack of this.filteredCNAcks) {
      if (userList.includes(ack.wyvernID)) {
        if (ack.cnid in output) {
          output[ack.cnid] += 1;
        } else {
          output[ack.cnid] = 1;
        }
      }
    }

    return output;
  }

  public archivedCNCount() {
    let result = 0;

    for (const CN of this.CNFilteredPublishedList) {
      if (CN.status === 'Archived') {
        result++;
      }
    }

    return result;
  }

  public async createCN() {
    const body = new HttpParams()
      .set('token', this.loginService.token)
      .set('trust', this.loginService.trust)
      .set('contactID', this.loginService.contactID + "");

    await this.http
      .post(
        `${this.servername}aimerapi/class/CN/Draft/Create`,
        body.toString(),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        }
      )
      .toPromise()
      .then(
        async (data: { success; params }) => {
          if (data.success) {
            const CN = data.params[0];
            const fields = CN.fields;

            fields.recordID = CN.id;
            fields.CNID = `${fields.ID}`;
            fields.updated = null;

            this.CN = fields;

            this.CNList.unshift(this.CN);
          }
        },
        (err) => {
          this.logError(err);
          // unauthorised
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }

  public deleteCN(recordID: string) {
    const body = new HttpParams()
      .set('token', this.loginService.token)
      .set('trust', this.loginService.trust)
      .set('contactID', this.loginService.contactID + "")
      .set('recordID', recordID);

    try {
      this.http
        .post(
          `${this.servername}aimerapi/class/CN/Draft/Delete`,
          body.toString(),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
          }
        )
        .toPromise()
        .catch(async (err) => {
          this.logError(err);
          // unauthorised
          if (err.status === 401) {
            await this.loginService.logout();
          }
        });
    } catch (exc) {}
  }

  public async loadLiveCNs() {


    const body = new HttpParams()
      .set('token', this.loginService.token)
      .set('trust', this.loginService.trust)
      .set('contactID', this.loginService.contactID + "");

    await this.http
      .post(
        `${this.servername}aimerapi/class/CN/CNList/Live`,
        body.toString(),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        }
      )
      .toPromise()
      .then(
        async (data: { success; params }) => {
          if (data.success) {
            this.CNList = data.params.map((record) => {
              const fields: ClinicalNotice = {
                ID: record.fields.ID,
                CNID: record.fields.ID,
                title: record.fields.Title,
                incCFR: record.fields['Include in CFR'] === true,
                incPLUS: record.fields['Include in PLUS'] === true,
                incCRM: record.fields['Include in Control Room'] === true,
                status: record.fields.status && record.fields.status,
                released: record.fields.released,
                updated: record.fields.updated,
                archived: record.fields.archived,
                html: record.fields.content_container,
                pdfUrl: record.fields['PDF Path'],
                firstPublished: record.fields.released,
                lastPublished: record.fields.updated,
                recordID: record.id,
                keywords: null,
                guidelines: null,
                regions: null,
                pgdPermissions: null,
                localClinicalUpdate: record.fields['Local Clinical Update'] === true
              };

              if (record.fields.Keywords) {
                fields.keywords = record.fields.Keywords.split(',');
              }

              if (record.fields.guidelines) {
                fields.guidelines = JSON.parse(record.fields.guidelines);
              }

              if(record.fields.regions) {
                fields.regions = record.fields.regions.split(',');
              }

              if(record.fields['pgd permissions']) {
                fields.pgdPermissions = record.fields['pgd permissions'].split(',');
              }

              return fields;
            });

            this.CNPublishedList = this.CNList.filter(
              (CN) => CN.status !== 'Draft'
            );
            if (this.CFR_only)
              this.CNPublishedList = this.CNList.filter((CN) => CN.incCFR);
          }
        },
        (err) => {
          this.logError(err);
          this.CNList = [];
          this.CNPublishedList = [];
          // unauthorised
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }

  public async addDraftCNs() {
    const body = new HttpParams()
      .set('token', this.loginService.token)
      .set('trust', this.loginService.trust)
      .set('contactID', this.loginService.contactID + "");

    await this.http
      .post(
        `${this.servername}aimerapi/class/CN/CNList/Draft`,
        body.toString(),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        }
      )
      .toPromise()
      .then(
        async (data: { success; params }) => {
          if (data.success) {
            this.CNDraftList = data.params.map((record) => {
              const fields = {
                ID: record.fields.ID,
                CNID: record.fields.ID,
                title: record.fields.Title,
                incCFR: record.fields['Include in CFR'],
                incPLUS: record.fields['Include in PLUS'],
                incCRM: record.fields['Include in Control Room'],
                status: record.fields.status,
                released: record.fields.released,
                updated: record.fields.updated,
                // lastModified: record.fields.lastModified,
                html: record.fields.content_container,
                pdfUrl: record.fields['PDF Path'],
                firstPublished: record.fields.released,
                lastPublished: record.fields.updated,
                recordID: record.id,
                keywords: null,
                guidelines: null,
                localClinicalUpdate: record.fields['Local Clinical Update'] === true
              };

              if (record.fields.Keywords) {
                fields.keywords = record.fields.Keywords.split(',');
              }

              if (record.fields.guidelines) {
                fields.guidelines = JSON.parse(record.fields.guidelines);
              }

              return fields;
            });

            this.CNList = [...this.CNDraftList, ...this.CNList];
          }
        },
        (err) => {
          this.logError(err);
          this.CNDraftList = [];
          // unauthorised
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }

  private addAcks() {
    const acks = this.getAckCountByCNID();

    for (const CN of this.CNList) {
      CN.ackCount = acks[CN.CNID];
    }
  }

  public async loadCNAcks() {
    let body = new HttpParams()
      .set('token', this.loginService.token)
      .set('trust', this.loginService.trust)
      .set('contactID', this.loginService.contactID + "")
      .set('regions', this.loginService.regions.join(';'))
      .set('mustHaveSameSubscription', this.loginService.matchUsersWithSubscription ? 'true' : 'false')
      .set('product', this.CFR_only ? 'CFR' : '');

    await this.http
      .post(`${this.servername}aimerapi/class/PGD/CNAcks`, body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .toPromise()
      .then(
        (data: { success; params }) => {
          if (data.success) {
            this.CNAcks = data.params;
          }
        },
        (err) => {
          this.logError(err);
          this.filteredCNAcks = [];
          if (err.status === 401) {
            this.loginService.logout();
          }
        }
      );

    return;
  }

  public getCNSForUser(wvyernID) {
    const results = [];

    for (const ack of this.filteredCNAcks) {
      if (ack.wyvernID === Number(wvyernID)) {
        results.push(ack);
      }
    }

    return results;
  }

  public getUsers(CNID: string) {
    const filteredAcks = this.filteredCNAcks.filter(
      (acks) => acks.cnid === Number(CNID)
    );

    const users = [];

    let dups = [];
    let userCount = 0;
    for (const user of this.userFilteredList) {
      const ack = filteredAcks.find(
        (acks) => acks.wyvernID === Number(user.ContactID)
      );

      if (ack) {
        if (dups.includes(user.ContactID)) {
          console.log('');
        } else {
          dups.push(user.ContactID);
        }
        user.AckTime = Date.parse(ack.timestamp);
        userCount++;
      } else {
        user.AckTime = 0;
      }

      users.push(user);
    }

    return users;
  }

  public loadStaffGroups() {
    if (this.StaffGroups.length > 0) {
      return;
    }

    this.StaffGroups = [{ name: 'Total', total: this.userFilteredList.length }];

    for (const user of this.userFilteredList) {
      const MGList = user.ManagementGroup.split(/\||,/);

      for (let MG of MGList) {
        MG = MG.trim();
        const result = this.StaffGroups.find(({ name }) => name === MG);

        if (result) {
          result.total = result.total + 1;
        } else {
          this.StaffGroups.push({ name: MG, total: 1 });
        }
      }
    }
  }

  public getCN(CNID: string) {
    return this.CNList.find((CN) => CN.CNID === CNID);
  }

  public isInStatus(name, statuses) {
    let result = false;

    for (const status of statuses) {
      if (name && status && name.toLowerCase() === status.toLowerCase()) {
        result = true;
      }
    }

    return result;
  }

  public getStatuses() {
    const statuses = [];

    for (const CN of this.CNList) {
      if (CN) {
        const status = statuses.find((elem) => elem.name === CN.status);

        if (status) {
          status.total = status.total + 1;
        } else if (CN.status) {
          statuses.push({ name: CN.status, total: 1 });
        }
      }
    }

    return statuses;
  }

  gListTags() {
    return this.gList.map(({ GLID, Title }) => ({
      value: GLID,
      display: Title,
    }));
  }

  getRegions() {

    const regions = [];

    this.userList.forEach((user) => {
      // split on vertical bar, comma, or semi-colon
      const regionsList = user.Region != null ? user.Region.split(/\||,|;/g) : [];
      regionsList.forEach((region: string) => {
        if(!regions.includes(region)){
          regions.push(region);
        }

      })
      
    });

    return regions.sort();

  }

  getPGDPermissions() {

    const permissions = [];

    this.userList.forEach((user) => {

      // split on vertical bar, comma, or semi-colon
      const managementGroups = user.ManagementGroup != null ? user.ManagementGroup.split(/\||,|;/g) : [];
      managementGroups.forEach((managementGroup: string) => {
        if(!permissions.includes(managementGroup)){
          permissions.push(managementGroup);
        }

      })
      
    })

    return permissions.sort();

  }

  public async getGuidelines() {
    const allGuidelines: any = await this.getAllGuidelines();
    const trustGuidelines: any = await this.getTrustGuidelines();

    return [...allGuidelines, ...trustGuidelines];
  }

  public async getQuizList(): Promise<QuizListItem[]> {
    const list: QuizListItem[] = [];
    try {
      const init = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      const fetchOne = fetch(
        'https://ppp-temp-files.s3.amazonaws.com/data/pppTopics.json',
        init
      );
      const fetchTwo = fetch(
        'https://ppp-temp-files.s3.amazonaws.com/data/pppCaseStudies.json',
        init
      );
      const fetchThree = fetch(
        'https://ppp-temp-files.s3.amazonaws.com/data/pppIssues.json',
        init
      );
      const fetchFour = fetch(
        'https://ppp-temp-files.s3.amazonaws.com/data/pppGuidelinesWithQuizzes.json',
        init
      );
      const fetchFive = fetch(
        'https://aace.s3.amazonaws.com/GLUpdates.json',
        init
      );
      const fetchSix = fetch(
        'https://jrcalc.s3.amazonaws.com/Feed-Sup/GLList.json',
        init
      );

      const results = await Promise.all([
        fetchOne,
        fetchTwo,
        fetchThree,
        fetchFour,
        fetchFive,
        fetchSix,
      ]);
      for (const [index, value] of results.entries()) {
        const json = await value.json();

        const linkPrefix = 'cpgcpd://quiz/';
        if (index === 0) {
          json.forEach((item) => {
            list.push({
              linkID: `${linkPrefix}${item.PackName}`,
              title: item.Title,
            });
          });
        } else if (index === 1 || index === 2) {
          json.forEach((item) => {
            list.push({
              linkID: `${linkPrefix}${item.Name}`,
              title: item.Title,
            });
          });
        } else if (index === 3) {
          const guids = json;

          // get results from other requests to create links
          const glUpdatedList = await results[4].json();
          const glList = await results[5].json();

          guids.forEach((id) => {
            const index = glUpdatedList.findIndex((gl) => gl.GLID === id);
            if (index !== 0) {
              list.push({
                linkID: `${linkPrefix}${glUpdatedList[index].GLID}`,
                title: glUpdatedList[index].Title,
              });
            } else {
              const index = glList.findIndex((gl) => gl.GLID === id);
              if (index !== 0) {
                list.push({
                  linkID: `${linkPrefix}${glUpdatedList[index].GLID}`,
                  title: glUpdatedList[index].Title,
                });
              }
            }
          });
        }
      }
    } catch (error) {
      console.log('Failed to get quiz list');
    }

    return list.sort((a, b) => {
      // changing the case (to upper or lower) ensures a case insensitive sort.
      const textA = a.title.toUpperCase();
      const textB = b.title.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }

  getAllGuidelines() {
    return new Promise((resolve) => {
      this.http
        .get('https://jrcalc.s3.amazonaws.com/Feed-Sup/GLList.json')
        .toPromise()
        .then(
          (data) => {
            if (data) {
              resolve(data);
            }
          },
          async (err) => {
            this.logError(err);
            this.gList = null;

            if (err.status === 401) {
              this.loginService.logout().catch(console.error);
            }
            resolve([]);
          }
        );
    });
  }

  getTrustGuidelines() {
    return new Promise((resolve) => {
      this.http
        .get(
          `https://jrcalc.s3.amazonaws.com/${this.loginService.trust}/JSON/CMGuidelineList.json`
        )
        .toPromise()
        .then(
          (data) => {
            if (data) {
              resolve(data);
            }
          },
          async (err) => {
            this.logError(err);
            this.gList = null;

            if (err.status === 401) {
              this.loginService.logout().catch(console.error);
            }
            resolve([]);
          }
        );
    });
  }
  
  async getUsageLogForUser(contactID: number, isCFR: boolean = false) {

    try {
     
      const body = new HttpParams()
        .set('token', this.loginService.token)
        .set('TAContactID', this.loginService.contactID + "")
        .set('contactID', contactID + "")
        .set('product', isCFR ? 'CFR' : 'PLUS')
        .set('trust', this.loginService.trust)

      let data: any  = await this.http
      .post(
        `${this.servername}aimerapi/class/getUsageLog`,
        body.toString(),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        }
      )
      .toPromise();

      if (data && data.success) {
        return data.result;
      }
    
    } catch (err) {
      this.logError(err);
      this.userFilteredList = [];
      if (err.status === 401) {
        this.loginService.logout();
      }
    }

    return [];
  }

  public logError(err) {
    console.error(err.message);
  }
}
